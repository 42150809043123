































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SortButton extends Vue {
  selected: null | number = null;
  items = [
    { title: this.$t.button.sort.new, value: 'DESC' },
    { title: this.$t.button.sort.old, value: 'ASC' },
  ];

  @Watch('selected')
  select(val: number, old: number) {
    if (val === undefined) this.selected = old;
    else this.$emit('change', this.items[val]?.value);
  }
}
