










































import { Component, Vue, Prop } from 'vue-property-decorator';
import UserAvatar from '@/components/UserAvatar.vue';
import { ITeacher } from '@/types/teacher';
import { ICurrentTimes } from '@/types/common';
import { AUTH } from '@/services/auth';
import { IUser } from '@/types/user';

@Component({
  components: {
    UserAvatar,
  },
})
export default class VideoCard extends Vue {
  @Prop({ default: false })
  @Prop() protected readonly loading!: boolean;
  @Prop() protected readonly id!: number;
  @Prop() protected readonly previewSource!: string;
  @Prop() protected readonly title!: string;
  @Prop() protected readonly teacher!: ITeacher;
  @Prop() protected readonly updatedAt!: string;
  @Prop() protected readonly framesCount?: number;
  @Prop() protected readonly fps?: number;

  get formattingTimeDate() {
    return this.$formattingDate(this.updatedAt);
  }

  get videoDuration(): number {
    if (this.framesCount && this.fps) return this.framesCount / this.fps;
    return 0;
  }

  get currentTime() {
    if (this.videoDuration) {
      return (((this.currentTimeFromLocalStorage) * 100) / this.videoDuration);
    }
    return 0;
  }

  get currentTimeFromLocalStorage() {
    const user: IUser | null = AUTH.getUser();
    if (!user) return 0;

    const data = localStorage.getItem('currentTimes');
    if (!data) return 0;

    const currentTimes: ICurrentTimes = JSON.parse(data);
    if (currentTimes[user.id] === undefined) return 0;
    if (currentTimes[user.id][this.id] === undefined) return 0;

    return currentTimes[user.id][this.id];
  }
}
